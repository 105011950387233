app.controller('startTfaController',
  ['$scope', '$http', '$location', 'apiHelper', 'portalHelper', 'authHelper', '$routeParams',
    'INTERCOM', 'CONSTANTS', 'SETTINGS', 'dynamicBreadcrumbs', '$ngConfirm', 'HttpAuth',
    function ($scope, $http, $location, apiHelper, portalHelper, authHelper, $routeParams,
      INTERCOM, CONSTANTS, SETTINGS, dynamicBreadcrumbs, $ngConfirm, HttpAuth) {


      $scope.isTfaChange = ($location.path() === '/TfaChange');

      $scope.validate_form = function () {
        return true; 
      };

      function loadData() {
        HttpAuth.get(apiHelper.getApiUrl() + '/auth/start_totp/').then(function (response) {
          $scope.totp = response.data;
        }, function (response) {
          portalHelper.showErrorCommon(response);
        });
      }

      portalHelper.portalReady(function () {
        $scope.me = authHelper.getMeShared();
        $scope.data_loaded = true;
        loadData();
      }).then(
        function (myDetails) {
          if (authHelper.isLoginPage()) {
            $scope.breadcrumb = dynamicBreadcrumbs.get('Setup TFA__login');
          } else {
            $scope.breadcrumb = dynamicBreadcrumbs.get('Setup TFA');
          }
        },
        function (error) {
          portalHelper.showErrorCommon(error);
        }
      );

      $scope.show_text_code = function () {
        portalHelper.showCopyableTextPopup('Your two-factor secret',
          'If your authenticator app does not have functionality to scan the QR code, you can type this code instead.',
          $scope.totp.secret_key
        );
      };

      $scope.create_otp = function () {
        if (!$scope.otp_code) {
          portalHelper.showPopupError('ERROR', 'no code provided');
          return;
        }

        var data = { 'secret_key': $scope.totp.secret_key, 'otp': $scope.otp_code };

        if ($scope.isTfaChange) {
          data['replace'] = true;
        }

        HttpAuth.post(apiHelper.getApiUrl() + '/auth/create_totp/', data).then(
          function (result) {
            if (!result.data.success) {
              portalHelper.showPopupError('Error', result.data.message);
              return;
            }

            if (authHelper.isLoginPage()) {
              portalHelper.showPopupOK('Success',
                'Your changes have been saved successfully.',
                authHelper.redirectLoggedIn);
              return;
            }

            portalHelper.showPopupOK('Success', 'Your changes have been saved successfully.');

            $scope.me.tfa_configured = true;
            $location.path('/TfaSetupIntro');
          }, function (response) {
            portalHelper.showErrorCommon(response);
          }
        );
      };

      $scope.cancel_otp = function () {
        if (authHelper.isLoginPage()) {
          $location.path('/TfaRequired');
          return;
        }

        $location.path('/PasswordChange');
      };
    }]);
