app.controller('TfaSetupIntroController',
  ['$scope', '$http', '$location', 'apiHelper', 'portalHelper', 'authHelper', '$routeParams',
    'INTERCOM', 'CONSTANTS', 'SETTINGS', 'dynamicBreadcrumbs', '$ngConfirm', 'HttpAuth',
    function ($scope, $http, $location, apiHelper, portalHelper, authHelper, $routeParams,
      INTERCOM, CONSTANTS, SETTINGS, dynamicBreadcrumbs, $ngConfirm, HttpAuth) {


      portalHelper.portalReady(function () {
        $scope.me = authHelper.getMeShared();
      }).then(
        function (myDetails) {
          if (authHelper.isLoginPage()) {
            $scope.breadcrumb = dynamicBreadcrumbs.get('Setup TFA__login');
          } else {
            $scope.breadcrumb = dynamicBreadcrumbs.get('Setup TFA');
          }
        },
        function (error) {
          portalHelper.showErrorCommon(error);
        }
      );

      $scope.changeTfaConfirm = function () {
        var popup = $ngConfirm({
          title: 'Change Two-factor authentication device',
          content: ('<b>Are you sure you want to change your Two-factor authentication device?</b><br/>' +
            'Once you complete this step, your old authentication app will no longer generate correct tokens.'
          ),
          columnClass: 'medium',
          type: 'orange',
          buttons: {
            Change: {
              btnClass: 'mat ui primary button',
              text: 'Yes, Change Device',
              action: function (scope, button) {
                $location.path('/TfaChange');
                $scope.$apply();
              }
            },
            Cancel: {
              btnClass: 'mat ui critical button'
            }
          }
        });
      };

      $scope.removeTfaConfirm = function () {
        var popup = $ngConfirm({
          title: 'Delete Two-factor authentication device?',
          content: ('<b>Are you sure you want to delete your Two-factor authentication device?</b><br/>' +
            'Once you complete, the system will no longer ask you to enter a Two-factor authentication' +
            'code making your account less secure.'
          ),
          columnClass: 'medium',
          type: 'orange',
          buttons: {
            Delete: {
              btnClass: 'mat ui primary button',
              text: 'Yes, Delete device',
              action: function (scope, button) {
                HttpAuth.post(apiHelper.getApiUrl() + '/auth/remove-tfa/', {}).then(
                  function (result) {
                    if (!result.data.success) {
                      portalHelper.showPopupError('Error', result.data.message);
                      return;
                    }

                    portalHelper.showPopupOK('Success',
                      'Your changes have been saved successfully.',
                      function () {
                        $scope.me.tfa_configured = false;
                        $scope.go_back();
                        $scope.$apply(); 
                      });

                  }, function (response) {
                    portalHelper.showErrorCommon(response);
                  }
                );
              }
            },
            Cancel: {
              btnClass: 'mat ui critical button'
            }
          }
        });
      };

      $scope.setup_tfa = function () {
        $location.path('/TfaSetup');
      };

      $scope.go_back = function () {
        if (authHelper.isLoginPage()) {
          $location.path('/TfaRequired');
          return;
        }

        $location.path('/PasswordChange');
      };
    }]);
