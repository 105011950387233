var app = angular.module('MatApp', ['MatRouting', 'ngRoute', 'ui.bootstrap', 'ngSanitize',
  'ui.select', 'ngCookies']);

angular.module('MatRouting', ['ngRoute', 'cp.ngConfirm']).config(function ($routeProvider) {
  $routeProvider
    .when('/', {
      redirectTo: '/Login'
    })
    .when('/TfaRequired', {
      templateUrl : '../' + 'html_templates/login_tfa_required.59a8a3f2.html',
      controller: 'loginTfaRequiredController'
    })
    .when('/TfaSetupIntro', {
      templateUrl : '../' + 'html_templates/tfa_setup_intro.b834b796.html',
      controller: 'TfaSetupIntroController'
    })
    .when('/TfaSetup', {
      templateUrl : '../' + 'html_templates/tfa_setup.290b5f38.html',
      controller: 'startTfaController'
    })
    .when('/Login', {
      template : '',
      controller: 'loginController'
    })
    .when('/Login/Return/:returnUrl/:idleTimeOut?', {
      template : '',
      controller: 'loginController'
    })
    .otherwise({
      redirectTo: '/Login'
    });
});
