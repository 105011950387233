app.controller('faqsView',
  ['$window', '$scope', '$q', '$location', '$routeParams', 'portalHelper', 'HttpAuth', 'apiHelper',
    'payerHelper', 'authHelper', '$http', 'dynamicBreadcrumbs',
    function ($window, $scope, $q, $location, $routeParams, portalHelper, HttpAuth, apiHelper,
      payerHelper, authHelper, $http, dynamicBreadcrumbs) {

      function loadData(success_callback) {
        var promises = [];

        if (authHelper.isUserPayer()) {
          promises.push(HttpAuth.get(apiHelper.getApiUrl() + '/api/payer/page/2.json'));
          promises.push(HttpAuth.get(apiHelper.getApiUrl() + '/api/payer/faq.json'));
        } else if (authHelper.isUserClient()) {
          promises.push(HttpAuth.get(apiHelper.getApiUrl() + '/api/client/page/2.json'));
          promises.push(HttpAuth.get(apiHelper.getApiUrl() + '/api/client/faq.json'));
        } else if (authHelper.isUserAdmin()) {
          promises.push(HttpAuth.get(apiHelper.getApiUrl() + '/api/admin/page/2.json'));
          promises.push(HttpAuth.get(apiHelper.getApiUrl() + '/api/admin/faq/payer.json'));
        }

        $q.all(promises).then(function (result) {
          $scope.data_loaded = true;
          $scope.page2 = result[0].data.content;
          $scope.faqs = angular.copy(result[1].data);

          if (success_callback) {
            success_callback();
          }
        }, function (result) {
          portalHelper.showErrorCommon(result);
        });
      }

      portalHelper.portalReady(function () {
        loadData();
      }).then(
        function (myDetails) {
          $scope.breadcrumb = dynamicBreadcrumbs.getDefault('FAQs');
        },
        function (error) {
          portalHelper.showErrorCommon(error);
        }
      );
    }]);
