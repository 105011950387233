app.controller('loginTfaRequiredController',
  ['$scope', '$http', '$location', 'apiHelper', 'portalHelper', 'authHelper', '$routeParams',
    'INTERCOM', 'CONSTANTS', 'SETTINGS', 'dynamicBreadcrumbs', '$ngConfirm',
    function ($scope, $http, $location, apiHelper, portalHelper, authHelper, $routeParams,
      INTERCOM, CONSTANTS, SETTINGS, dynamicBreadcrumbs, $ngConfirm) {

      portalHelper.portalReady(function () {
      });

      $scope.logout = function () {
        authHelper.doLogoutAll(false);
      };

      $scope.tfa_setup_intro = function () {
        $location.path('/TfaSetupIntro');
      };
    }]);
